import { cn } from '~/utils';
import type { IconProps } from '../types';

export function ClassicsIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M15.1593 2.72148C15.6761 2.42617 16.3142 2.42617 16.8363 2.72148L25.896 7.89473L26.1228 7.98438V8.03184L28.6488 9.47676C29.3132 9.85645 29.6402 10.6369 29.4451 11.3752C29.2499 12.1135 28.5802 12.6303 27.8156 12.6303H4.1853C3.42065 12.6303 2.75092 12.1135 2.55581 11.3752C2.36069 10.6369 2.68764 9.85645 3.3521 9.47676L5.8728 8.03184V7.98438L6.10483 7.9L15.1593 2.72148ZM5.8728 14.3125H9.2478V24.4375H11.3572V14.3125H14.7322V24.4375H17.2634V14.3125H20.6384V24.4375H22.7478V14.3125H26.1228V24.6643C26.1544 24.6801 26.1861 24.7012 26.2177 24.7223L28.749 26.4098C29.366 26.8211 29.6455 27.591 29.4292 28.3029C29.213 29.0148 28.5539 29.5 27.8103 29.5H4.1853C3.44174 29.5 2.78784 29.0148 2.57163 28.3029C2.35542 27.591 2.62964 26.8211 3.2519 26.4098L5.78315 24.7223C5.81479 24.7012 5.84643 24.6854 5.87807 24.6643V14.3125H5.8728Z"
        fill="currentcolor"
      />
    </svg>
  );
}
