import { cn } from '~/utils';
import type { IconProps } from '../types';

export function PendingIcon(props: IconProps) {
  return (
    <svg
      class={cn('size-5', props.class)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13Z"
        fill="currentColor"
      />
      <path
        d="M8.75 4.75C8.75 4.33579 8.41421 4 8 4C7.58579 4 7.25 4.33579 7.25 4.75V8C7.25 8.41421 7.58579 8.75 8 8.75H10.25C10.6642 8.75 11 8.41421 11 8C11 7.58579 10.6642 7.25 10.25 7.25H8.75V4.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
