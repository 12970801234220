import { cn } from '~/utils';
import type { IconProps } from '../types';

export function RomanceIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M16 27.7L14.6 26.4C9.2 21.5 5.5 18.1 5.5 13.9C5.5 10.5 8.2 7.8 11.6 7.8C13.5 7.8 15.3 8.7 16 10.1C16.7 8.7 18.5 7.8 20.4 7.8C23.8 7.8 26.5 10.5 26.5 13.9C26.5 18.1 22.8 21.5 17.4 26.4L16 27.7Z"
        fill="currentcolor"
      />
    </svg>
  );
}
