import { For, splitProps } from 'solid-js';
import { getLoggedInMenuItems, getLoggedOutMenuItems } from '~/data/header';
import type { AuthUser } from '~/modules/api';
import { cn } from '~/utils';
import { Button, Dropdown } from '../ui';
import { AvatarImage } from './AvatarImage';

export interface UserAccountMenuProps {
  user?: AuthUser;
  class?: string;
}

function LoggedOutMenu() {
  const items = getLoggedOutMenuItems();

  return (
    <For each={items}>
      {({ href, label, variant, trackable }) => (
        <Button
          href={href}
          variant={variant ?? 'primary'}
          class="h-8 md:h-10"
          trackable={trackable}
        >
          {label}
        </Button>
      )}
    </For>
  );
}

function LoggedInMenu(props: UserAccountMenuProps) {
  const { dropdown: options, buttons } = getLoggedInMenuItems(props.user);

  return (
    <>
      <For each={buttons}>
        {({ href, label, variant, trackable }) => (
          <Button
            href={href}
            variant={variant ?? 'primary'}
            trackable={trackable}
            class="cursor-pointer h-8 md:h-10"
          >
            {label}
          </Button>
        )}
      </For>

      <Dropdown
        {...{ options }}
        trigger={
          <AvatarImage
            src={props.user?.avatarImageUrl}
            displayName={props.user?.displayName ?? 'User'}
          />
        }
      />
    </>
  );
}

/**
 * The top-level menu for user account actions (login, logout, etc)
 */
export function UserAccountMenu(props: UserAccountMenuProps) {
  const [{ user }, rest] = splitProps(props, ['user', 'class']);
  return (
    <aside {...rest} class={cn('flex items-center gap-3', props.class)}>
      {props.user ? <LoggedInMenu user={user} /> : <LoggedOutMenu />}
    </aside>
  );
}
