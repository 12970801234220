import { cn } from '~/utils';
import type { IconProps } from '../types';

export function SportIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M7.39766 6.25L11.7242 10.5766C12.877 9.18008 13.5625 7.3875 13.5625 5.4375C13.5625 4.69102 13.4609 3.96992 13.273 3.28437C11.0539 3.76172 9.04297 4.80273 7.39766 6.25ZM6.25 7.39766C4.80273 9.04297 3.76172 11.0539 3.28437 13.273C3.96992 13.4609 4.69102 13.5625 5.4375 13.5625C7.3875 13.5625 9.18008 12.877 10.5816 11.7293L6.25 7.39766ZM16 3C15.6293 3 15.2586 3.01523 14.893 3.0457C15.0859 3.8125 15.1875 4.61484 15.1875 5.4375C15.1875 7.83945 14.3191 10.0332 12.882 11.7344L16 14.8523L24.6023 6.25C22.307 4.22891 19.2957 3 16 3ZM5.4375 15.1875C4.61484 15.1875 3.8125 15.0859 3.0457 14.893C3.01523 15.2586 3 15.6293 3 16C3 19.2957 4.22891 22.307 6.25 24.6023L14.8523 16L11.7344 12.882C10.0332 14.3191 7.83945 15.1875 5.4375 15.1875ZM28.9543 17.107C28.9848 16.7414 29 16.3707 29 16C29 12.7043 27.7711 9.69297 25.75 7.39766L17.1477 16L20.2656 19.118C21.9617 17.6809 24.1605 16.8125 26.5625 16.8125C27.3852 16.8125 28.1875 16.9141 28.9543 17.107ZM28.7156 18.727C28.0301 18.5391 27.309 18.4375 26.5625 18.4375C24.6125 18.4375 22.8199 19.123 21.4184 20.2707L25.75 24.6023C27.1973 22.9621 28.2434 20.9512 28.7156 18.727ZM20.2707 21.4184C19.123 22.8199 18.4375 24.6125 18.4375 26.5625C18.4375 27.309 18.5391 28.0301 18.727 28.7156C20.9461 28.2383 22.957 27.1973 24.6023 25.75L20.2758 21.4234L20.2707 21.4184ZM19.118 20.2656L16 17.1477L7.39766 25.75C9.68789 27.7711 12.6992 29 16 29C16.3707 29 16.7414 28.9848 17.107 28.9543C16.9141 28.1875 16.8125 27.3852 16.8125 26.5625C16.8125 24.1605 17.6809 21.9668 19.118 20.2656Z"
        fill="currentcolor"
      />
    </svg>
  );
}
