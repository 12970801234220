import { cn } from '~/utils';
import type { IconProps } from '../types';

export function MysteryIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.59991 26.7215L5.90683 27.2784C5.7088 27.4331 5.46746 27.5197 5.21992 27.5197C4.61347 27.5197 4.11841 27.0247 4.11841 26.4182V14.3815C4.11841 7.82193 9.44034 2.5 15.9999 2.5C22.5595 2.5 27.8815 7.82193 27.8815 14.3815V26.4182C27.8815 27.0247 27.3864 27.5197 26.7799 27.5197C26.5324 27.5197 26.2911 27.4331 26.093 27.2784L25.4 26.7215C24.5707 26.0593 23.3702 26.1645 22.6709 26.9628L20.7835 29.1287C20.5793 29.3639 20.276 29.5 19.9604 29.5C19.6448 29.5 19.3478 29.3639 19.1374 29.1287L17.4913 27.2413C16.7054 26.3378 15.3007 26.3378 14.5086 27.2413L12.8625 29.1287C12.6583 29.3639 12.355 29.5 12.0394 29.5C11.7238 29.5 11.4268 29.3639 11.2164 29.1287L9.32895 26.9628C8.62967 26.1645 7.42915 26.0593 6.59991 26.7215ZM25.3601 11.0434L16.8087 15.3191C16.8087 15.3191 19.6932 19.0794 23.1205 17.1516C26.3781 15.3191 25.3601 11.0434 25.3601 11.0434ZM15.1912 15.3191L6.63983 11.0434C6.63983 11.0434 5.6218 15.3191 8.87948 17.1516C12.3067 19.0794 15.1912 15.3191 15.1912 15.3191Z"
        fill="currentcolor"
      />
    </svg>
  );
}
