import { cn } from '~/utils';
import type { IconProps } from '../types';

export function ExplosionIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <g clip-path="url(#clip0_3049_17556)">
        <path
          d="M15.6125 1.21023C15.8219 0.875854 16.2531 0.757104 16.6031 0.944604C16.9531 1.1321 17.0969 1.55398 16.9344 1.91335L12.65 11.4384C12.7188 11.5102 12.7844 11.5852 12.8469 11.6602L15.8844 9.95085C16.2125 9.76648 16.6219 9.85398 16.85 10.1509C17.0781 10.4477 17.0469 10.8696 16.7812 11.1352L14.0625 13.854H11.8281C11.4156 12.6884 10.3062 11.854 9 11.854C7.69375 11.854 6.58125 12.6884 6.17188 13.854H3.68125L1.32187 12.2227C1.01875 12.0134 0.9125 11.6102 1.075 11.2759C1.2375 10.9415 1.61875 10.779 1.97187 10.8884L5.00938 11.8384C5.10313 11.7165 5.2 11.5977 5.30312 11.4852L3.35625 8.24148C3.16563 7.92585 3.23438 7.5196 3.51562 7.2821C3.79688 7.0446 4.20938 7.04773 4.4875 7.28523L7.6875 10.0321C7.73438 10.0196 7.78125 10.0071 7.82812 9.99773L8.25313 5.53835C8.29063 5.15398 8.6125 4.86023 9 4.86023C9.3875 4.86023 9.70937 5.15398 9.74687 5.53835L10.1687 9.97273L15.6125 1.21023ZM2 14.8571H16H17C17.5531 14.8571 18 15.304 18 15.8571C18 16.4102 17.5531 16.8571 17 16.8571H1C0.446875 16.8571 0 16.4102 0 15.8571C0 15.304 0.446875 14.8571 1 14.8571H2ZM9 0.857104C9.41562 0.857104 9.75 1.19148 9.75 1.6071V3.1071C9.75 3.52273 9.41562 3.8571 9 3.8571C8.58438 3.8571 8.25 3.52273 8.25 3.1071V1.6071C8.25 1.19148 8.58438 0.857104 9 0.857104Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3049_17556">
          <rect
            width="18"
            height="16"
            fill="currentColor"
            transform="translate(0 0.857178)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
