import { cn } from '~/utils';
import type { IconProps } from '../types';

export function FantasyIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <g clip-path="url(#clip0_3049_17549)">
        <path
          d="M9.87521 4.26147L8.45607 3.90601C8.27834 3.86226 8.14709 3.71187 8.12795 3.52866C8.10881 3.34546 8.20451 3.17046 8.36584 3.08843L9.48146 2.53062L8.30021 1.64468C8.14982 1.53257 8.08693 1.33569 8.14709 1.15522C8.20724 0.974756 8.37404 0.857178 8.56271 0.857178H11.6252H12.5002H12.9377C13.7635 0.857178 14.5428 1.24546 15.0377 1.90718L16.6127 4.00718C16.7822 4.23413 16.8752 4.5103 16.8752 4.79468C16.8752 5.51929 16.2873 6.10718 15.5627 6.10718H14.9748C14.51 6.10718 14.0643 5.92397 13.7361 5.59585L13.3752 5.23218H12.5002V5.82007C12.5002 6.49819 12.8502 7.12983 13.4244 7.49077L16.3393 9.31187C17.217 9.86147 17.7502 10.8212 17.7502 11.8576C17.7502 13.5146 16.4076 14.8572 14.7479 14.8572H13.8127H12.0627H1.13341C1.04318 14.8572 0.952946 14.8462 0.870915 14.8189C0.619352 14.7423 0.414274 14.5564 0.315837 14.313C0.277556 14.2201 0.255681 14.1244 0.250212 14.0205C0.244743 13.9193 0.258415 13.8209 0.285759 13.7279C0.362321 13.4763 0.548259 13.2712 0.794352 13.1701C0.876384 13.1373 0.963884 13.1154 1.05412 13.1099L12.0983 12.1228C12.3252 12.1037 12.5002 11.9123 12.5002 11.6826C12.5002 11.565 12.4537 11.4529 12.3717 11.3708L11.1576 10.1568C10.3373 9.33647 9.87521 8.22358 9.87521 7.06421V5.82007V4.26147ZM14.2502 2.83413C14.2502 2.8314 14.2502 2.82866 14.2502 2.82593C14.2502 2.82319 14.2502 2.82046 14.2502 2.81772V2.83413ZM14.2147 3.03647L12.9459 2.71929C12.9404 2.75483 12.9377 2.79038 12.9377 2.82593C12.9377 3.1896 13.2303 3.48218 13.594 3.48218C13.8838 3.48218 14.1272 3.29624 14.2147 3.03647ZM3.82951 4.04272C4.27521 3.64624 4.9342 3.59976 5.42912 3.93062L9.00021 6.30952V7.06421C9.00021 7.96108 9.2299 8.83608 9.65646 9.60718H3.31271C3.12951 9.60718 2.96545 9.49233 2.90256 9.3228C2.83966 9.15327 2.88888 8.95913 3.02834 8.83882L4.92599 7.20913L0.753337 7.85171C0.561931 7.88179 0.373259 7.78062 0.291227 7.60562C0.209196 7.43062 0.250212 7.22007 0.395134 7.09155L3.82951 4.04272Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3049_17549">
          <rect
            width="17.5"
            height="14"
            fill="currentColor"
            transform="translate(0.25 0.857178)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
