import { cn } from '~/utils';
import type { IconProps } from '../types';

export function ArtAndDesignIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M6.98223 4.76989L13.1085 10.8962L9.39618 14.6085L3.26989 8.48223C2.24337 7.45571 2.24337 5.79641 3.26989 4.76989C4.29641 3.74337 5.95571 3.74337 6.98223 4.76989ZM21.2504 28.0001C18.738 28.0001 16.6334 26.233 16.1225 23.8706L22.3706 17.6225C24.7283 18.1381 26.5001 20.238 26.5001 22.7504C26.5001 22.9332 26.4908 23.116 26.472 23.2941C26.3876 24.1144 26.9501 25.0003 27.7751 25.0003H28.0001C28.8297 25.0003 29.5 25.6705 29.5 26.5002C29.5 27.3298 28.8297 28.0001 28.0001 28.0001H21.2504ZM25.9986 4.90582L27.0954 6.00265C28.2672 7.17447 28.2672 9.07283 27.0954 10.2446L24.4518 12.8883L19.1083 7.54945L21.7519 4.90582C22.9237 3.734 24.8221 3.734 25.9939 4.90582H25.9986ZM6.1057 20.5567L18.0489 8.60878L23.3925 13.9523L11.4445 25.8955C11.1305 26.2096 10.7368 26.4393 10.3102 26.5611L5.43542 27.9533C5.04169 28.0657 4.61983 27.9579 4.32922 27.6673C4.03861 27.3767 3.9308 26.9549 4.04329 26.5611L5.43542 21.6863C5.55729 21.2551 5.78697 20.8661 6.10102 20.552L6.1057 20.5567Z"
        fill="currentcolor"
      />
    </svg>
  );
}
