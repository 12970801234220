import { cn } from '~/utils';
import type { IconProps } from '../types';

export function ComedyIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M5.31297 22.1869C7.07455 24.997 10.0764 28.8317 13.5576 29.359C17.0389 29.8863 21.0174 27.1121 23.51 24.9431C25.2056 23.4751 26.1643 21.36 26.4939 19.1191L27.9439 9.2746C28.0278 8.68741 27.9798 8.08224 27.6383 7.6029C26.7515 6.35062 24.205 3.93594 17.4523 2.91135C10.6996 1.88675 7.57786 3.44461 6.36752 4.37933C5.90016 4.73884 5.67847 5.30206 5.59458 5.88926L4.14457 15.7337C3.81503 17.9687 4.12061 20.2755 5.31896 22.1869H5.31297ZM13.8752 12.9176C13.4138 12.5881 12.8686 12.3604 12.2694 12.2705C11.6702 12.1806 11.083 12.2345 10.5498 12.4143C10.2442 12.5161 9.89667 12.3364 9.93262 12.0128C9.93861 11.9829 9.93861 11.9469 9.9446 11.917C10.1363 10.6108 11.3347 9.712 12.6229 9.90973C13.9112 10.1075 14.7979 11.3178 14.6062 12.624C14.6002 12.654 14.5942 12.6899 14.5882 12.7199C14.5283 13.0374 14.1448 13.1033 13.8812 12.9176H13.8752ZM22.036 13.8463C21.9761 14.1639 21.5926 14.2298 21.329 14.0441C20.8676 13.7145 20.3223 13.4868 19.7232 13.3969C19.124 13.3071 18.5368 13.361 18.0035 13.5407C17.698 13.6426 17.3504 13.4628 17.3864 13.1393C17.3924 13.1093 17.3924 13.0734 17.3984 13.0434C17.5901 11.7372 18.7885 10.8385 20.0767 11.0362C21.3649 11.2339 22.2517 12.4442 22.06 13.7505C22.054 13.7804 22.048 13.8164 22.042 13.8463H22.036ZM21.3949 19.8021C20.2205 22.4805 17.3924 24.1462 14.3965 23.6908C11.4006 23.2354 9.17766 20.8028 8.82414 17.8968C8.76423 17.3994 9.35741 17.1718 9.73489 17.4953C11.1669 18.7416 12.9465 19.6104 14.9477 19.91C16.949 20.2096 18.9083 19.916 20.6339 19.143C21.0893 18.9393 21.5926 19.3348 21.3889 19.7961L21.3949 19.8021Z"
        fill="currentcolor"
      />
    </svg>
  );
}
