import { cn } from '~/utils';
import type { IconProps } from '../types';

export function BiographyIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M4.625 7.875C4.625 5.18359 6.80859 3 9.5 3H24.125H25.75C26.6488 3 27.375 3.72617 27.375 4.625V20.875C27.375 21.7738 26.6488 22.5 25.75 22.5V25.75C26.6488 25.75 27.375 26.4762 27.375 27.375C27.375 28.2738 26.6488 29 25.75 29H24.125H9.5C6.80859 29 4.625 26.8164 4.625 24.125V7.875ZM7.875 24.125C7.875 25.0238 8.60117 25.75 9.5 25.75H22.5V22.5H9.5C8.60117 22.5 7.875 23.2262 7.875 24.125ZM20.0625 9.5C20.0625 8.63805 19.7201 7.8114 19.1106 7.2019C18.5011 6.59241 17.6745 6.25 16.8125 6.25C15.9505 6.25 15.1239 6.59241 14.5144 7.2019C13.9049 7.8114 13.5625 8.63805 13.5625 9.5C13.5625 10.362 13.9049 11.1886 14.5144 11.7981C15.1239 12.4076 15.9505 12.75 16.8125 12.75C17.6745 12.75 18.5011 12.4076 19.1106 11.7981C19.7201 11.1886 20.0625 10.362 20.0625 9.5ZM11.125 18.4375C11.125 18.8844 11.4906 19.25 11.9375 19.25H21.6875C22.1344 19.25 22.5 18.8844 22.5 18.4375C22.5 16.193 20.682 14.375 18.4375 14.375H15.1875C12.943 14.375 11.125 16.193 11.125 18.4375Z"
        fill="currentcolor"
      />
    </svg>
  );
}
