import { cn } from '~/utils';
import type { IconProps } from '../types';

export function AnimeIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <path
        d="M14.1059 2.5C21.549 2.5 27.5758 8.54486 27.5758 16C27.5758 23.4551 21.549 29.5 14.1059 29.5C10.4537 29.5 7.145 28.0415 4.71621 25.679C4.41487 25.3837 4.33652 24.9256 4.52938 24.552C4.72224 24.1783 5.13808 23.9674 5.55393 24.0397C6.14456 24.1422 6.74724 24.1964 7.36799 24.1964C13.2079 24.1964 17.945 19.4473 17.945 13.5893C17.945 9.62365 15.7754 6.17031 12.5631 4.35022C12.1954 4.13928 12.0086 3.71741 12.099 3.30759C12.1894 2.89777 12.539 2.5904 12.9609 2.55424C13.3405 2.52411 13.7202 2.50603 14.1059 2.50603V2.5Z"
        fill="currentcolor"
      />
    </svg>
  );
}
