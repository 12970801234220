import { cn } from '~/utils';
import type { IconProps } from '../types';

export function ScaryIcon(props: IconProps) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class={cn('size-4', props.class)}
    >
      <g clip-path="url(#clip0_3049_17542)">
        <path
          d="M13 13.3228C14.8281 12.0384 16 10.0697 16 7.85718C16 3.99155 12.4187 0.857178 8 0.857178C3.58125 0.857178 0 3.99155 0 7.85718C0 10.0666 1.17188 12.0384 3 13.3228C3 13.3353 3 13.3447 3 13.3572V15.3572C3 16.1853 3.67188 16.8572 4.5 16.8572H6V15.3572C6 15.0822 6.225 14.8572 6.5 14.8572C6.775 14.8572 7 15.0822 7 15.3572V16.8572H9V15.3572C9 15.0822 9.225 14.8572 9.5 14.8572C9.775 14.8572 10 15.0822 10 15.3572V16.8572H11.5C12.3281 16.8572 13 16.1853 13 15.3572V13.3572C13 13.3447 13 13.3353 13 13.3228ZM3 8.85718C3 8.32674 3.21071 7.81804 3.58579 7.44296C3.96086 7.06789 4.46957 6.85718 5 6.85718C5.53043 6.85718 6.03914 7.06789 6.41421 7.44296C6.78929 7.81804 7 8.32674 7 8.85718C7 9.38761 6.78929 9.89632 6.41421 10.2714C6.03914 10.6465 5.53043 10.8572 5 10.8572C4.46957 10.8572 3.96086 10.6465 3.58579 10.2714C3.21071 9.89632 3 9.38761 3 8.85718ZM11 6.85718C11.5304 6.85718 12.0391 7.06789 12.4142 7.44296C12.7893 7.81804 13 8.32674 13 8.85718C13 9.38761 12.7893 9.89632 12.4142 10.2714C12.0391 10.6465 11.5304 10.8572 11 10.8572C10.4696 10.8572 9.96086 10.6465 9.58579 10.2714C9.21071 9.89632 9 9.38761 9 8.85718C9 8.32674 9.21071 7.81804 9.58579 7.44296C9.96086 7.06789 10.4696 6.85718 11 6.85718Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3049_17542">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.857178)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
