import { type VariantProps } from 'class-variance-authority';
import { createSignal, onCleanup } from 'solid-js';
import type { TextFieldVariants } from '~/components/ui';
import { Autocomplete } from '~/components/ui';
import type { SearchResultItemDto } from '~/libs/api-client';
import { ResultKind, api } from '~/modules/api';
import { link } from '~/utils';

export type SearchBarProps = VariantProps<typeof TextFieldVariants>;

export function SearchBar(props: SearchBarProps) {
  const [searchText, setSearchText] = createSignal('');
  const [items, setItems] = createSignal<SearchResultItemDto[]>([]);

  onCleanup(() => {
    setSearchText('');
  });

  const onSearchChange = (query: string) => {
    const search = query.trim();

    if (search.length === 0) setItems([]);

    if (search.length < 3) return;

    api.Search.searchByText({
      query: {
        search,
      },
    }).then(res => {
      if (res.data) {
        setItems(res.data.slice(0, 8));
      }
    });
  };

  const handleEnter = (event: KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      searchText().length >= 3 &&
      items().length > 0
    ) {
      window.location.href = link.toSearch(searchText());
    }
  };

  return (
    <Autocomplete
      {...props}
      placeholder="Search Dreamflare..."
      value={searchText()}
      suggestions={items().map(item => ({
        ...item,
        value: item.id,
        label: item.name,
        imageUrl: item.imageUrl,
        link:
          item.kind === ResultKind.AUTHOR
            ? link.toUser({
                username: item.id,
              })
            : link.toContent({ id: item.id, kind: item.kind }),
        trackable: true,
        trackingProperties: JSON.stringify({
          section: 'search-bar',
        }),
      }))}
      debounceTimeout={500}
      onChange={e => setSearchText(e.currentTarget.value)}
      onChangeDebounced={onSearchChange}
      onKeyDown={handleEnter}
    />
  );
}
