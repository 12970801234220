import { splitProps, type JSXElement } from 'solid-js';
import {
  AnimationIcon,
  AnimeIcon,
  ArtAndDesignIcon,
  BiographyIcon,
  ClassicsIcon,
  ComedyIcon,
  ExplosionIcon,
  FantasyIcon,
  HistoryIcon,
  MysteryIcon,
  RomanceIcon,
  ScaryIcon,
  SciFiIcon,
  SportIcon,
} from '~/components/icons/genres';
import type { IconProps } from '~/components/icons/types';
import { type Genre } from '~/data/genres';

// Mapping of genres to icon components
const genreIconsMapping: Record<
  Genre['slug'],
  (props: IconProps) => JSXElement
> = {
  adventure: ExplosionIcon,
  animation: AnimationIcon,
  anime: AnimeIcon,
  art_and_design: ArtAndDesignIcon,
  animals: FantasyIcon, //TODO FIND THE RIGHT ICON!
  biography: BiographyIcon,
  classics: ClassicsIcon,
  comedy: ComedyIcon,
  fantasy: FantasyIcon,
  history: HistoryIcon,
  mystery: MysteryIcon,
  scary: ScaryIcon,
  sci_fi: SciFiIcon,
  sport: SportIcon,
  romance: RomanceIcon,
  kids: AnimeIcon, // TODO
  education: AnimationIcon, // TODO
  western: AnimeIcon, // TODO
  action: AnimeIcon, // TODO
  crime: AnimeIcon, // TODO
  superhero: AnimeIcon, // TODO
};

export interface GenreIconPickerProps extends IconProps {
  genre: Genre['slug'];
  class?: string;
}

export function GenreIconPicker(props: GenreIconPickerProps) {
  const [, rest] = splitProps(props, ['genre']);
  const IconComponent = genreIconsMapping[props.genre] || ExplosionIcon;

  return <IconComponent {...rest} />;
}
